import axios from "axios";
import get from "lodash/get";

export const logError = (e) => {
  // alert(e);
  handleStatus(e);
  return get(e, "response.data", {});
};

export const handleStatus = (e) => {
  const response = e.response ? e.response : e;
  const status = response ? response.status : "unknown";
  switch (status) {
    case 404:
    case 429:
    case 500:
    case "unknown":
      //alert(e);
      return console.error(e);
    default:
      // window.location.replace(`/problem?code=${status}`);
      break;
  }
  // alert(e);
  return e;
};

export const requestService = (config) => {
  let { url, isNewAPI, ...otherConfig } = config;

  url = url.slice(0, 1) !== "/" && url.indexOf("http") === -1 ? "/" + url : url;

  const baseAPIURL = window.REACT_APP_API_URL;
  const domain = url.indexOf("http") >= 0 ? "" : baseAPIURL || "";
  const lang = isLanguageSet ? localStorage.getItem("i18nextLng") : "";
  const authorization = (window.userKey && `Bearer ${window.userKey}`) || "";
  const headers = {
    authorization,
    Pragma: "no-cache",
    lang,
  };
  //alert(`${domain}${url}`);
  return axios
    .request({
      headers,
      url: `${domain}${url}`,
      ...otherConfig,
    })
    .then((res) => {
      if (res.status !== 200) {
        console.error("PLATFORM SERVICE ERROR", res);
      }
      return res;
    })
    .then((res) => res.data)
    .catch(logError);
};

var isLanguageSet = false;

export const getService = (url, params) => {
  return requestService({
    method: "get",
    url,
    params,
  });
};

export const postService = (url, data, other) => {
  return requestService({
    method: "post",
    url,
    data,
    ...other,
  });
};

export const putService = (url, data, other) => {
  return requestService({
    method: "put",
    url,
    data,
    ...other,
  });
};

export const deleteService = (url, params) => {
  return requestService({
    method: "delete",
    url,
    params,
  });
};

export const getFile = (filePath) => {
  return axios
    .get(filePath)
    .then((res) => {
      return res.data;
    })
    .catch(logError);
};
