import React from "react";

/**
 * This component is used to display the required
 * privacy statement which can be found in a link in the
 * about tab.
 */
const Privacy = () => {
  return (
    <div>
      <h1>Privacy Statement</h1>
    </div>
  );
};

export default Privacy;
