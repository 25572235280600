export const UserLaunchedTyto = ({ launchedBy }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          height: "100%",

          padding: "4px 4px",
        }}
      >
        {launchedBy} is currently accessing the tyto video feed. Please ask this user to stop sharing in order to access the tyto video feed.
      </span>
    </div>
  );
};
