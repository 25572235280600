// import { Welcome } from "./sample/Welcome";

import React, { useEffect, useState } from "react";

import OutlinedCard from "./modules/sidePan";

export default function Tab() {
  return (
    <div>
      <OutlinedCard />
    </div>
  );
}
