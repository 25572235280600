import "./App.css";

import * as microsoftTeams from "@microsoft/teams-js";

import { Provider, teamsTheme } from "@fluentui/react-northstar";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Redirect, Route, HashRouter as Router } from "react-router-dom";

import Overview from "./Overview";
import Privacy from "./Privacy";
import Tab from "./Tab";
import TabConfig from "./TabConfig";
import TermsOfUse from "./TermsOfUse";
import forEach from "lodash/forEach";
import { getFile } from "../utils/ServiceUtils";
import { useTeams } from "msteams-react-base-component";

// https://fluentsite.z22.web.core.windows.net/quick-start

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export const UserContext = createContext();
export default function App() {
  const { theme } = useTeams({})[0];
  const [meetingContext, setMeetingContext] = useState();

  const performInit = async () => {
    await microsoftTeams.app.initialize();

    const context = await microsoftTeams.app.getContext();
    setMeetingContext(context);
  };

  useEffect(() => {
    performInit();
  }, []);

  return (
    <UserContext.Provider value={meetingContext}>
      <Provider
        theme={theme || teamsTheme}
        styles={{ backgroundColor: "#eeeeee" }}
      >
        <SetUpAppReady>
          <Router>
            <Route exact path="/">
              <Redirect to="/tab" />
            </Route>
            <>
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/termsofuse" component={TermsOfUse} />
              <Route exact path="/tab" component={Tab} />
              <Route exact path="/config" component={TabConfig} />
              <Route exact path="/overview" component={Overview} />
            </>
          </Router>
        </SetUpAppReady>
      </Provider>
    </UserContext.Provider>
  );
}
const SetUpAppReady = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    const setConfigs = async () => {
      const configJSON = await getFile("/config.json");
      forEach(configJSON, (value, name) => {
        window[name] = value;
      });
      setIsReady(true);
    };

    setConfigs();
  }, []);
  if (isReady) {
    return children;
  }
  return null;
};
