import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import logo from "../../assets/logoBig.png";

export const GenericMessageCard = () => {
  return (
    <div
      style={{
        padding: "4px",
      }}
    >
      <CardContent>
        <img
          className="logo"
          src={logo}
          alt="andorhealth"
          width="90"
          height="20"
        />
        <Typography
          sx={{
            paddingTop: "10px",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          AndorTyto app to pull tyto care video feeds during the session.
        </Typography>
      </CardContent>
    </div>
  );
};
