import "./App.css";
import "../components/modules/sidePan.css";

import * as microsoftTeams from "@microsoft/teams-js";

import CardContent from "@mui/material/CardContent";
import React from "react";
import Typography from "@mui/material/Typography";
import logo from "../assets/logoBig.png";

/**
 * The 'Config' component is used to display your group tabs
 * user configuration options.  Here you will allow the user to
 * make their choices and once they are done you will need to validate
 * their choices and communicate that to Teams to enable the save button.
 */
const Overview = () => {
  // Initialize the Microsoft Teams SDK
  microsoftTeams.app.initialize();

  return (
    <div className="sidePanContent" style={{ marginTop: "10px" }}>
      <div className="logoText">
        <CardContent>
          <img
            className="logo"
            src={logo}
            alt="andorhealth"
            width="90"
            height="20"
          />
          <Typography
            sx={{
              paddingTop: "10px",
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            AndorTyto app to pull tyto care video feeds into the session.
          </Typography>
        </CardContent>
      </div>
    </div>
  );
};

export default Overview;
