import "./sidePan.css";

import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React from "react";
import Typography from "@mui/material/Typography";
import logo from "../../assets/logoBig.png";

export const ButtonsCard = ({
	isDeviceOnline,
	isDevicePaired,
	getDummyStagingUrl,
	getStagingUrl,
	buttonLoaders,
	browserLaunch,
	launchReviewUrl,
	handlePairDevice,
	handleRefresh,
	infoMessage,
}) => {
	return (
		<div className="sidePanContent">
			<div className="logoText" style={{ paddingTop: "4px" }}>
				<img
					className="logo"
					src={logo}
					alt="andorhealth"
					width="90"
					height="20"
				/>

				<CardContent
					sx={{
						justifyContent: "center",
						alignContent: "center",
						textAlign: "center",
						width: "100%",
					}}
				>
					<Typography
						sx={{
							justifyContent: "center",
							alignContent: "center",
							textAlign: "center",
						}}
					>
						{isDevicePaired && isDeviceOnline ? (
							<>
								<span style={{ fontSize: "14px", fontWeight: "600" }}>
									Click on LAUNCH DEVICE to control the tytocare device
								</span>
								<br />
								<span style={{ fontSize: "12px", fontWeight: "500" }}>
									You may ask the patient to select 'Join Online Visit' on their
									device if you have trouble connecting
								</span>
							</>
						) : (
							<span style={{ fontSize: "12px", fontWeight: "600" }}>
								There is no Tytocare Device paired for this visit. Please select
								PAIR DEVICE to send a pairing request. You can REFRESH this page
								once the patient has paired their device to LAUNCH DEVICE
							</span>
						)}
					</Typography>
					<div
						style={{
							display: "flex",
							flexDirection: "column",

							backgroundColor: "#e8eaeb",
							width: "100%",
							padding: "10px",
							marginTop: "10px",
							marginBottom: "10px",
							borderRadius: "10px",
						}}
					>
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "center",
							}}
						>
							{isDeviceOnline ? (
								<>
									<div style={{ padding: "4px" }}>
										<CheckCircleIcon
											style={{
												background: "#057441",
												color: "#FFFFFF",
												borderRadius: "20px",
											}}
										/>
									</div>
									<div style={{ padding: "4px" }}>Device Online</div>
								</>
							) : (
								<>
									<div style={{ padding: "4px" }}>
										<HighlightOffIcon
											style={{
												background: "#D61E1E",
												color: "#FFFFFF",
												borderRadius: "20px",
											}}
										/>
									</div>
									<div style={{ padding: "4px" }}>Device Online</div>
								</>
							)}
						</div>
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "center",
							}}
						>
							{isDevicePaired ? (
								<>
									<div style={{ padding: "4px" }}>
										<CheckCircleIcon
											style={{
												background: "#057441",
												color: "#FFFFFF",
												borderRadius: "20px",
											}}
										/>
									</div>
									<div style={{ padding: "4px" }}>Device Paired</div>
								</>
							) : (
								<>
									<div style={{ padding: "4px" }}>
										<HighlightOffIcon
											style={{
												background: "#D61E1E",
												color: "#FFFFFF",
												borderRadius: "20px",
											}}
										/>
									</div>
									<div style={{ padding: "4px" }}>Device Paired</div>
								</>
							)}
						</div>
					</div>

					<CardActions
						sx={{
							justifyContent: "center",
							alignContent: "center",
							textAlign: "center",
							display: "flex",
							flexDirection: "column",
							width: "100%",
						}}
					>
						<Button
							size="small"
							onClick={() => getStagingUrl()}
							sx={
								!isDevicePaired && !isDeviceOnline
									? {
											backgroundColor: "#d7dadd",
											color: "white",
											marginBottom: "4px",
											width: "100%",
											marginLeft: "8px",
											":hover": {
												backgroundColor: "#d7dadd",
												color: "white",
											},
									  }
									: {
											backgroundColor: "#3bb8c1",
											color: "white",
											marginBottom: "4px",
											width: "100%",
											marginLeft: "8px",

											":hover": {
												backgroundColor: "#3bb8c1",
												color: "white",
											},
									  }
							}
							disabled={!isDevicePaired && !isDeviceOnline}
						>
							{buttonLoaders?.isDeviceLaunching ? (
								<>
									<span style={{ paddingRight: "4px" }}>Launching...</span>
									<CircularProgress size={14} sx={{ color: "white" }} />
								</>
							) : (
								"Launch Device"
							)}
						</Button>
						<Button
							size="small"
							onClick={() => launchReviewUrl()}
							sx={
								!isDevicePaired && !isDeviceOnline
									? {
											backgroundColor: "#d7dadd",
											color: "white",
											marginBottom: "4px",
											width: "100%",

											":hover": {
												backgroundColor: "#d7dadd",
												color: "white",
											},
									  }
									: {
											backgroundColor: "#3bb8c1",
											color: "white",
											marginBottom: "4px",
											width: "100%",
											marginLeft: "8px",

											":hover": {
												backgroundColor: "#3bb8c1",
												color: "white",
											},
									  }
							}
							disabled={!isDevicePaired && !isDeviceOnline}
						>
							{buttonLoaders?.isReviewUrlLaunching ? (
								<>
									<span style={{ paddingRight: "4px" }}>Review Recording</span>
									<CircularProgress size={14} sx={{ color: "white" }} />
								</>
							) : (
								"Review Recording"
							)}
						</Button>
						<Button
							size="small"
							onClick={() => handlePairDevice()}
							sx={
								isDevicePaired && isDeviceOnline
									? {
											backgroundColor: "#d7dadd",
											color: "white",
											marginBottom: "4px",
											width: "100%",

											":hover": {
												backgroundColor: "#d7dadd",
												color: "white",
											},
									  }
									: {
											backgroundColor: "#3bb8c1",
											color: "white",
											marginBottom: "4px",
											width: "100%",
											marginLeft: "8px",

											":hover": {
												backgroundColor: "#3bb8c1",
												color: "white",
											},
									  }
							}
						>
							{buttonLoaders?.isPairing ? (
								<>
									<span style={{ paddingRight: "4px" }}>Pair Device</span>
									<CircularProgress size={14} sx={{ color: "white" }} />
								</>
							) : (
								" Pair Device"
							)}
						</Button>
						<button
							size="small"
							onClick={() => handleRefresh()}
							style={{
								marginTop: "4px",
								fontSize: "12px",
								letterSpacing: "0.5px",
							}}
						>
							{buttonLoaders?.isRefreshing ? (
								<>
									<span style={{ paddingRight: "4px" }}>Refreshing...</span>
									<CircularProgress size={14} sx={{ color: "white" }} />
								</>
							) : (
								" REFRESH"
							)}
						</button>
					</CardActions>
					<div
						style={{
							padding: "4px",
							color: infoMessage?.color,
							fontSize: "12px",
							letterSpacing: "0.5px",
							fontWeight: "600",
						}}
					>
						{infoMessage?.message}
					</div>
				</CardContent>
			</div>
		</div>
	);
};
